import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/global.scss';
import './src/styles/legacy.css';
import './src/styles/form.scss';

import CookieConsenter from './src/util/cookietools';

const trackingScriptUpdater = initConsent(false);

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const newPath = location.pathname;
  const oldPath = (prevLocation?prevLocation.pathname:null);
  if (newPath!==oldPath) {
    const pagePath = (location ? (location.pathname + location.search + location.hash) : undefined);
    setTimeout(function() {
      trackingScriptUpdater(window.threewireConsent, pagePath);
    }, 32);
  }
};

/**
 * initConsent sets global variable threewireConsent and provides for the
 * updating of this value depending on whether or not a cookie banner is used.
 * @param boolean - whether or not to use cookie banner
 * @return function - for updating consent variable at a given event
 */
function initConsent(useCookieBanner) {
  const cookieUtil = CookieConsenter();
  window.threewireConsent = cookieUtil.queryByCookie(useCookieBanner);
  if (useCookieBanner) initializeCookieHandling();

  function updateTrackingScripts(isConsented, pagePath) {
    const trackingTag = document.getElementById('threewire');
    if (isConsented) {
      if (trackingTag) trackingTag.remove();
      cookieUtil.addScript('threewire', '/threewire.js');
      if (pagePath) cookieUtil.utm.init(pagePath);
    } else if (trackingTag) {
      trackingTag.remove();
      cookieUtil.utm.clear();
      window.location.reload(true); // Reload page from server to refresh window object
    }
  }

  function initializeCookieHandling() {
    cookieUtil.pollForCookie(1000).then(function(value) {
      window.threewireConsent = value;
      if (!window.twScriptActive) updateTrackingScripts(value);
    }).catch(function(error) {
      window.threewireConsent = false;
    });
    cookieUtil.addConsentListener(setConsent, 1000).catch(function(error) {
      console.log('Unable to set consent listener!');
    });
  }

  function setConsent(isConsented) {
    if (isConsented!==window.threewireConsent) {
      window.threewireConsent = isConsented;
      updateTrackingScripts(isConsented);
    }
  }

  return updateTrackingScripts;
}
